import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { getTransformedSliderChoices } from 'utils';
import getUrlParameter from 'utils/get-url-parameter';

import { Wrapper, FieldsWrapper, StyledField, Button } from './form-generator.styles';

export const FormGeneratorView = (props) => {
  const {
    data: { formFields, button },

    buttonText,
    isHidden,

    values,
    errors,
    touched,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = props;

  return (
    <Wrapper isHidden={isHidden} noValidate onSubmit={handleSubmit}>
      {formFields.map(({ id, type, label, choices, isRequired, conditionalLogic, description }) => {
        let field = null;
        const fieldName = `input_${id}`;

        const marks = {};
        if (type === 'multiselect') {
          const parsedChoices = JSON.parse(choices);
          const choicesWithCorrectValues = getTransformedSliderChoices(parsedChoices);
          choicesWithCorrectValues.forEach(({ text, value }) => {
            marks[value] = text;
          });
        }

        let radios = [];
        if (type === 'radio' && values[fieldName]) {
          radios = JSON.parse(choices).map((choice) => ({
            ...choice,
            isSelected: choice.value === values[fieldName],
          }));
        }

        let checkboxes = [];
        if (type === 'checkbox' && values[fieldName]) {
          checkboxes = JSON.parse(choices).map((choice) => ({
            ...choice,
            isSelected: !!values[fieldName].find((value) => value === choice.value),
          }));
        }

        let isFieldVisible = true;

        const parsedConditionalLogic = JSON.parse(conditionalLogic);
        if (parsedConditionalLogic) {
          const {
            actionType,
            rules: [rule],
          } = parsedConditionalLogic;
          const ruleFieldName = `input_${rule.fieldId}`;

          if (actionType === 'show') {
            if (values[ruleFieldName] && values[ruleFieldName] !== rule.value)
              isFieldVisible = false;
          } else if (actionType === 'hide') {
            if (values[ruleFieldName] && values[ruleFieldName] === rule.value)
              isFieldVisible = false;
          }
        }

        if (!isFieldVisible) return null;

        switch (type) {
          case 'name':
            field = (
              <>
                <StyledField
                  id={`${fieldName}_3`}
                  label="First name"
                  name={`${fieldName}_3`}
                  value={values[`${fieldName}_3`]}
                  autoComplete="given-name"
                  required={isRequired}
                  error={touched[`${fieldName}_3`] && errors[`${fieldName}_3`]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <StyledField
                  id={`${fieldName}_6`}
                  label="Last name"
                  name={`${fieldName}_6`}
                  value={values[`${fieldName}_6`]}
                  autoComplete="family-name"
                  required={isRequired}
                  description={description}
                  error={touched[`${fieldName}_6`] && errors[`${fieldName}_6`]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </>
            );
            break;
          case 'text':
            field = (
              <StyledField
                id={fieldName}
                label={label}
                name={fieldName}
                value={values[fieldName]}
                required={isRequired}
                description={description}
                error={touched[fieldName] && errors[fieldName]}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
            break;

          case 'email':
            field = (
              <StyledField
                id={fieldName}
                label={label}
                name={fieldName}
                type="email"
                value={values[fieldName]}
                autoComplete="email"
                required={isRequired}
                description={description}
                error={touched[fieldName] && errors[fieldName]}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
            break;
          case 'website':
            field = (
              <StyledField
                id={fieldName}
                label={label}
                name={fieldName}
                type="url"
                value={values[fieldName]}
                required={isRequired}
                description={description}
                error={touched[fieldName] && errors[fieldName]}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
            break;
          case 'phone':
            field = (
              <StyledField
                id={fieldName}
                label={label}
                name={fieldName}
                type="tel"
                value={values[fieldName]}
                autocomplete="tel"
                required={isRequired}
                description={description}
                error={touched[fieldName] && errors[fieldName]}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
            break;
          case 'textarea':
            field = (
              <StyledField
                id={fieldName}
                label={label}
                name={fieldName}
                rows={4}
                value={values[fieldName]}
                required={isRequired}
                description={description}
                error={touched[fieldName] && errors[fieldName]}
                component="textarea"
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
            break;
          case 'select':
            field = (
              <StyledField
                label={label}
                value={values[fieldName]}
                options={JSON.parse(choices)}
                required={isRequired}
                description={description}
                component="select"
                onChange={(event) => {
                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, event.currentTarget.value);
                }}
              />
            );
            break;
          case 'radio':
            field = (
              <StyledField
                label={label}
                name={fieldName}
                value={values[fieldName]}
                radios={radios}
                required={isRequired}
                description={description}
                component="radio"
                onChange={(event) => {
                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, event.currentTarget.value);
                }}
              />
            );
            break;
          case 'checkbox':
            field = (
              <StyledField
                label={label}
                name={fieldName}
                checkboxes={checkboxes}
                required={isRequired}
                description={description}
                error={touched[fieldName] && errors[fieldName]}
                component="checkbox"
                onChange={(event) => {
                  const checkboxValues = values[fieldName];
                  const checkboxNewValues = [];

                  const currentTargetValue = event.currentTarget.value;
                  const currentTargetChecked = event.currentTarget.checked;

                  checkboxValues.forEach((value) => {
                    if (value !== currentTargetValue) {
                      checkboxNewValues.push(value);
                    }
                  });

                  if (currentTargetChecked === true) checkboxNewValues.push(currentTargetValue);

                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, checkboxNewValues);
                }}
              />
            );
            break;
          case 'multiselect':
            field = (
              <StyledField
                label={label}
                name={fieldName}
                value={Number(values[fieldName])}
                marks={marks}
                required={isRequired}
                description={description}
                component="slider"
                onChange={(value) => {
                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, value);
                }}
              />
            );
            break;
          case 'captcha':
            field = (
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                onChange={(value) => {
                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, value);
                }}
              />
            );
            break;
          case 'hidden':
            const value = typeof window !== 'undefined' && getUrlParameter(label);

            if (value) {
              values[fieldName] = value;

              field = (
                <StyledField
                  id={fieldName}
                  type="hidden"
                  name={fieldName}
                  value={value}
                  required={false}
                />
              );
            }
            break;
          default:
        }

        return (
          <FieldsWrapper doable={type === 'name'} key={id}>
            {field}
          </FieldsWrapper>
        );
      })}

      <Button type="submit" disabled={isSubmitting} submitting={isSubmitting}>
        {isSubmitting ? 'Submitting' : buttonText || button.text}
      </Button>
    </Wrapper>
  );
};
