import * as React from 'react';

import { IconicLink } from '../header-iconic-link';

import { Wrapper, Inner, Section, SectionTitle } from './header-company-dropdown.styles';

export const CompanyDropdown = ({ className, links }) => (
  <Wrapper className={className}>
    <Inner>
      {links.map(
        ({ id, label, description, url, acf: { companyLinkName }, childItems: children }) =>
          children.nodes.length > 0 ? (
            <Section>
              <SectionTitle>{label}</SectionTitle>
              {children.nodes.map(({ id, label, description, url, acf: { companyLinkName } }) => (
                <IconicLink
                  title={label}
                  description={description}
                  to={url}
                  linkName={false}
                  key={id}
                />
              ))}
            </Section>
          ) : (
            <IconicLink
              title={label}
              description={description}
              to={url}
              linkName={companyLinkName}
              key={id}
            />
          )
      )}
    </Inner>
  </Wrapper>
);
