import { createGlobalStyle, css } from 'styled-components';
import normalize from 'styled-normalize';

import { fontFamily, fontSize, color, media } from './variables';

const globalStyles = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
    -ms-overflow-style: scrollbar;
  }

  body {
    position: relative;
    min-width: 320px;
    min-height: 100vh;
    font-family: ${fontFamily.primary};
    font-size: ${fontSize.base};
    color: ${color.text.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  .content-wrapper {
    position: relative;
  }

  .ReactModal__Overlay {
    z-index: 999;
    background-color: rgba(19, 19, 33, 0.9) !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .ReactModal__Content {
    position: static !important;
    max-width: 620px;
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding-top: 40px !important;
    padding-right: 10px !important;
    padding-bottom: 40px !important;
    padding-left: 10px !important;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden !important; // Fix for Edge
  }
  div#hs-en-cookie-confirmation-buttons-area {
    display: flex;
  }

  iframe[src*="https://apenterprise.io/"]
  {
    ${media.xs} {
      display: none;
    }
  }
`;

export const GlobalStyles = createGlobalStyle`${normalize} ${globalStyles}`;

export const HiddenWidgetsStyle = createGlobalStyle`
  #fc_frame{
    display:none;
  }

  iframe[src*="https://apenterprise.io/"]
  {
    display: none;
  }
`;
