import styled from 'styled-components';

import { color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';

export const Inner = styled.div`
  position: relative;
  background-color: ${color.text.tertiary};
  border-radius: 8px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05), 0 8px 15px 0 rgba(0, 0, 0, 0.05);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 285.5px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    transform: translateY(-100%);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: -230px;
  min-width: 620px;
  padding-top: 25px;
  transform: translateY(100%);
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 290px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  padding: 30px 40px;

  & > a {
    margin-bottom: 15px;
  }
`;

export const LinksWrapper = styled.div`
  padding: 40px 30px;
`;

export const IllustrationWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;

  img {
    height: auto;
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  span {
    text-align: center;
    width: 100%;
    padding: 15px 26px;
  }
`;

export const Section = styled.div`
  a {
    margin-bottom: 10px;

    h4 {
      color: #363c4c;
      font-weight: normal;
    }
  }
`;

export const SectionTitle = styled.span`
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  color: #a7aec1;
  text-transform: uppercase;
  margin-bottom: 14px;
  letter-spacing: 1px;
`;
