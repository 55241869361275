import styled from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Checkbox } from 'components/shared/checkbox';
import { Radio } from 'components/shared/radio';

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
  margin-bottom: 15px;

  ${media.xs} {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;

  ${media.xs} {
    margin-bottom: -10px;
  }
`;

export const StyledRadio = styled(Radio)`
  margin-right: 25px;
  margin-bottom: 15px;

  ${media.xs} {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const RadiosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;

  ${media.xs} {
    margin-bottom: -10px;
  }
`;

export const Description = styled.span`
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  color: ${color.text.secondary};
  transition: ${transition.base};
  margin-top: 7.5px;
`;

export const Error = styled(Description)`
  color: #fa0309;
`;

export const Label = styled.label`
  display: inline-block;
  vertical-align: top;
  font-size: ${fontSize.xs};
  font-weight: 500;
  line-height: normal;
  color: ${color.text.secondary};
  margin-bottom: 10px;

  ${media.xs} {
    font-size: 12px;
  }
`;
