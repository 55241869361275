import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Container = styled.div`
  max-width: 1175px;
  margin-right: auto;
  margin-left: auto;

  ${media.lg} {
    max-width: 960px;
  }

  ${media.md} {
    max-width: 720px;
  }

  ${media.sm} {
    max-width: 540px;
  }

  ${media.xs} {
    max-width: 100%;
    padding-right: 24px;
    padding-left: 24px;
  }
`;
