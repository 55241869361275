import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { fontSize } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Modal } from 'components/shared/modal';

export const StyledModal = styled(Modal)`
  ${(p) => css`
    ${p.showImage &&
    css`
      padding-top: 222px !important;
      overflow: hidden;
    `}
  `}
`;

export const StyledTitle = styled.span`
  display: block;
  font-size: 36px;
  line-height: 48px;
  max-width: 356px;
  margin-bottom: 24px;
`;

export const Description = styled.div`
  font-size: ${fontSize.sm};
  line-height: 28px;
  color: #6b7696;
  max-width: 492px;

  p {
    margin-top: 0;
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Image = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  height: 190px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-top: 32px;
`;
