import { useState, useEffect } from 'react';

// Modified version of https://gist.github.com/gragland/4e3d9b1c934a18dc76f585350f97e321

export function useWindowSize() {
  const isClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (!isClient) return false;

    function updateSize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
