import * as React from 'react';

import { Wrapper, Circle, Text, Input } from './radio.styles';

export const Radio = ({ className, name, value, text, selected, onChange }) => (
  <Wrapper className={className} selected={selected}>
    <Circle />
    <Text>{text}</Text>
    <Input name={name} type="radio" value={value} onChange={onChange} />
  </Wrapper>
);
