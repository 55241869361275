import * as React from 'react';

import { IconicLink } from '../header-iconic-link';

import { Wrapper, Inner, FeaturesSection } from './developers-dropdown.styles';

export const DevelopersDropdown = ({ className, links }) => {
  const developersLinks = links.filter(({ acf: { sectionName } }) => sectionName === 'developers');

  return (
    <Wrapper className={className}>
      <Inner>
        <FeaturesSection>
          {developersLinks.map(({ id, label, description, acf: { developersLinkName }, url }) => (
            <IconicLink
              title={label}
              description={description}
              to={url}
              linkName={developersLinkName}
              key={id}
            />
          ))}
        </FeaturesSection>
      </Inner>
    </Wrapper>
  );
};
