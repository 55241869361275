import * as React from 'react';

import { Select } from 'components/shared/select';
import { Slider } from 'components/shared/slider';
import { TextField } from 'components/shared/text-field';

import {
  Label,
  Description,
  Error,
  RadiosWrapper,
  StyledRadio,
  CheckboxesWrapper,
  StyledCheckbox,
} from './field.styles';

export const Field = (props) => {
  const {
    className,
    id,
    label,
    name,
    value,
    options,
    marks,
    radios,
    checkboxes,
    required,
    description,
    error,
    component,
    onBlur,
    onChange,
    ...textFieldAttributes
  } = props;

  let field;
  switch (component) {
    case 'input':
      field = (
        <TextField
          id={id}
          name={name}
          value={value}
          invalid={!!error}
          onBlur={onBlur}
          onChange={onChange}
          {...textFieldAttributes}
        />
      );
      break;
    case 'textarea':
      field = (
        <TextField
          id={id}
          name={name}
          value={value}
          invalid={!!error}
          as="textarea"
          onBlur={onBlur}
          onChange={onChange}
          {...textFieldAttributes}
        />
      );
      break;
    case 'select':
      field = <Select name={name} value={value} options={options} onChange={onChange} />;
      break;
    case 'slider':
      field = <Slider value={value} marks={marks} onChange={onChange} />;
      break;
    case 'radio':
      field = (
        <RadiosWrapper>
          {radios.map(({ value, text, isSelected }) => (
            <StyledRadio
              name={name}
              value={value}
              text={text}
              selected={isSelected}
              key={value}
              onChange={onChange}
            />
          ))}
        </RadiosWrapper>
      );
      break;
    case 'checkbox':
      field = (
        <CheckboxesWrapper>
          {checkboxes.map(({ value, text, isSelected }) => (
            <StyledCheckbox
              name={name}
              value={value}
              text={text}
              selected={isSelected}
              invalid={!!error}
              key={value}
              onChange={onChange}
            />
          ))}
        </CheckboxesWrapper>
      );
      break;
    default:
      field = (
        <TextField
          id={id}
          name={name}
          value={value}
          invalid={!!error}
          onBlur={onBlur}
          onChange={onChange}
          {...textFieldAttributes}
        />
      );
  }

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={id}>
          {label}
          {required && ' *'}
        </Label>
      )}
      {field}
      {!!description && !error && <Description>{description}</Description>}
      {!!error && <Error>{error}</Error>}
    </div>
  );
};
