import * as React from 'react';

import { Modal } from 'components/shared/modal';

import { LogoWrapper, StyledLogo, StyledTitle, Message } from './success-modal.styles';

export const SuccessModalView = ({ title, description, onClose }) => (
  <Modal onClose={onClose}>
    <LogoWrapper>
      <StyledLogo />
    </LogoWrapper>
    <StyledTitle size="sm">{title}</StyledTitle>
    <Message>{description}</Message>
    <div className="success-modal-gtm" />
  </Modal>
);
