import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { getFluidImage } from 'utils';

import DevelopersLinkIcon1 from './icons/developers-link-icon1.url.svg';
import DevelopersLinkIcon2 from './icons/developers-link-icon2.url.svg';
import DevelopersLinkIcon3 from './icons/developers-link-icon3.url.svg';
import FeaturesLinkIcon1 from './icons/features-link-icon1.url.svg';
import FeaturesLinkIcon2 from './icons/features-link-icon2.url.svg';
import FeaturesLinkIcon3 from './icons/features-link-icon3.url.svg';
import FeaturesLinkIcon4 from './icons/features-link-icon4.url.svg';
import InsightsLinkIcon1 from './icons/insights-link-icon1.url.svg';
import InsightsLinkIcon2 from './icons/insights-link-icon2.url.svg';
import InsightsLinkIcon3 from './icons/insights-link-icon3.url.svg';
import SolutionsLinkIcon1 from './icons/solutions-link-icon1.url.svg';
import SolutionsLinkIcon3 from './icons/solutions-link-icon3.url.svg';
import SolutionsLinkIcon4 from './icons/solutions-link-icon4.url.svg';
import SolutionsLinkIcon5 from './icons/solutions-link-icon5.url.svg';
import SolutionsLinkIcon6 from './icons/solutions-link-icon6.url.svg';
import SolutionsLinkIcon7 from './icons/solutions-link-icon7.url.svg';
import SolutionsLinkIcon8 from './icons/solutions-link-icon8.url.svg';
import {
  ButtonWrapper,
  FeaturesLink,
  FeaturesLinkIconWrapper,
  FeaturesLinkText,
  InsightsLink,
  InsightsLinkContent,
  InsightsLinkDescription,
  InsightsLinkIconWrapper,
  InsightsLinkIllustrationWrapper,
  InsightsLinkText,
  Section,
  SectionInner,
  SectionLink,
  SectionLinksWrapper,
  SectionTitle,
  SolutionsLink,
  SolutionsLinkIconWrapper,
  SolutionsLinkText,
  StyledButton,
  Wrapper,
} from './mobile-navigation.styles';

export const MobileNavigationView = (props) => {
  const { items, buttonText, buttonUrl, buttonText2, buttonUrl2, isOpen, illustrationDownload } =
    props;

  const menuItems = items.nodes.filter((item) => item.parentId === null);

  const solutionsLinks = menuItems.filter(
    ({ acf: { sectionName } }) => sectionName === 'solutions'
  );
  const productsLinks = menuItems.filter(({ acf: { sectionName } }) => sectionName === 'products');
  const featuresLinks = menuItems.filter(({ acf: { sectionName } }) => sectionName === 'features');
  const developersLinks = menuItems.filter(
    ({ acf: { sectionName } }) => sectionName === 'developers'
  );
  const insightsLinks = menuItems.filter(({ acf: { sectionName } }) => sectionName === 'insights');
  const otherLinks = menuItems.filter(({ acf: { sectionName } }) => sectionName === 'other');

  const companyPolicies = menuItems.filter(
    ({ acf: { sectionName } }) => sectionName === 'companyPolicies'
  );

  const linkIcons = {
    'employee-benefits': SolutionsLinkIcon1,
    acquisition: SolutionsLinkIcon4,
    goodwill: SolutionsLinkIcon3,
    'service-providers': SolutionsLinkIcon5,
    'brand-sales': SolutionsLinkIcon6,
    'employee-rewards': SolutionsLinkIcon7,
    api: SolutionsLinkIcon8,

    'bulk-gift-cards': FeaturesLinkIcon1,
    'gift-card-api': FeaturesLinkIcon2,
    'visa-card': FeaturesLinkIcon3,
    'international-reach': FeaturesLinkIcon4,

    blog: InsightsLinkIcon1,
    customer: InsightsLinkIcon2,
    video: InsightsLinkIcon3,

    'api-docs': DevelopersLinkIcon1,
    'get-started': DevelopersLinkIcon2,
    'api-refs': DevelopersLinkIcon3,
  };

  return (
    <Wrapper open={isOpen}>
      <Container>
        <Section>
          <SectionTitle>Solutions</SectionTitle>
          <SectionInner>
            {solutionsLinks.map(({ id, label, url, acf: { linkName } }) => (
              <SolutionsLink to={url} key={id}>
                <SolutionsLinkIconWrapper>
                  <img src={linkIcons[linkName]} alt="" width={40} height={40} />
                </SolutionsLinkIconWrapper>
                <SolutionsLinkText>{label}</SolutionsLinkText>
              </SolutionsLink>
            ))}
          </SectionInner>
        </Section>

        {productsLinks.length > 0 && (
          <Section>
            <SectionLinksWrapper>
              {productsLinks.map(({ id, label, url }) => (
                <SectionLink to={url} key={id}>
                  {label}
                </SectionLink>
              ))}
            </SectionLinksWrapper>
          </Section>
        )}

        <Section>
          <SectionTitle>Features</SectionTitle>
          <SectionInner>
            {featuresLinks.map(({ id, label, url, acf: { linkName } }) => (
              <FeaturesLink to={url} key={id}>
                <FeaturesLinkIconWrapper>
                  <img src={linkIcons[linkName]} alt="" width={40} height={40} />
                </FeaturesLinkIconWrapper>
                <FeaturesLinkText>{label}</FeaturesLinkText>
              </FeaturesLink>
            ))}
          </SectionInner>
        </Section>

        <Section>
          <SectionTitle>Developers</SectionTitle>
          <SectionInner>
            {developersLinks.map(({ id, label, url, acf: { linkName } }) => (
              <FeaturesLink to={url} key={id}>
                <FeaturesLinkIconWrapper>
                  <img src={linkIcons[linkName]} alt="" width={40} height={40} />
                </FeaturesLinkIconWrapper>
                <FeaturesLinkText>{label}</FeaturesLinkText>
              </FeaturesLink>
            ))}
          </SectionInner>
        </Section>

        <Section>
          <SectionTitle>Insights</SectionTitle>
          <SectionInner>
            {insightsLinks.map(({ id, label, description, url, acf: { linkName } }) => (
              <InsightsLink to={url} key={id}>
                {linkName && linkName !== 'download' && (
                  <InsightsLinkIconWrapper>
                    <img src={linkIcons[linkName]} alt="" width={40} height={40} />
                  </InsightsLinkIconWrapper>
                )}

                <InsightsLinkContent>
                  {linkName === 'download' && (
                    <InsightsLinkIllustrationWrapper>
                      <GatsbyImage
                        fluid={getFluidImage(illustrationDownload)}
                        alt=""
                        loading="eager"
                      />
                    </InsightsLinkIllustrationWrapper>
                  )}

                  <InsightsLinkText>{label}</InsightsLinkText>
                  {description && <InsightsLinkDescription>{description}</InsightsLinkDescription>}

                  {linkName === 'download' && (
                    <StyledButton to={url} component={Link} arrow={false}>
                      Download guide
                    </StyledButton>
                  )}
                </InsightsLinkContent>
              </InsightsLink>
            ))}
          </SectionInner>
        </Section>

        <Section>
          <SectionTitle>Other</SectionTitle>
          <SectionLinksWrapper>
            {otherLinks.map(({ id, label, url }) => (
              <SectionLink to={url} key={id}>
                {label}
              </SectionLink>
            ))}
          </SectionLinksWrapper>
        </Section>

        <Section>
          <SectionTitle>Company Policies</SectionTitle>
          <SectionLinksWrapper>
            {companyPolicies.map(({ id, label, url }) => (
              <SectionLink direction="column" to={url} key={id}>
                {label}
              </SectionLink>
            ))}
          </SectionLinksWrapper>
        </Section>

        <ButtonWrapper>
          <Button theme="fill" component={Link} to={buttonUrl}>
            {buttonText}
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button theme="bordered" component={Link} to={buttonUrl2}>
            {buttonText2}
          </Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};
