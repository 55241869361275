import * as React from 'react';

import { ArrowRightIcon } from 'components/icons';

import { Wrapper, TextWrapper, ArrowWrapper } from './button.styles';

export const Button = (props) => {
  const { className, component, children, theme, arrow, ...attributes } = props;

  return (
    <Wrapper
      className={className}
      as={component}
      arrow={arrow ? 1 : 0}
      theme={theme}
      {...attributes}
    >
      <TextWrapper>{children}</TextWrapper>
      {arrow && (
        <ArrowWrapper>
          <ArrowRightIcon />
        </ArrowWrapper>
      )}
    </Wrapper>
  );
};

Button.defaultProps = {
  arrow: true,
  theme: 'fill',
};
