export const color = {
  primary: '#00c1de',
  text: {
    primary: '#2c295d',
    orange: '#ff6d3b',
    orangeLight: '#fea383',
    purple: '#a76af5',
    purpleLight: '#ddc2ff',
    secondary: '#56556c',
    tertiary: '#ffffff',
    light: 'rgba(255, 255, 255, 0.61)',
  },
};
