import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';

export const Link = ({ children, to, activeClassName, partiallyActive, ...rest }) => {
  const isInternal = /^\/(?!\/)/.test(to);

  if (isInternal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} {...rest}>
      {children}
    </a>
  );
};
