import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { MobileNavigationView } from './mobile-navigation.view';

export const MobileNavigation = ({ isOpen }) => {
  const {
    wpSharedBlock: {
      acf: { buttonText, buttonUrl, buttonText2, buttonUrl2 },
    },
    menuItems,

    illustrationDownload,
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "mobile-menu" }) {
          acf {
            buttonText
            buttonUrl
            buttonText2
            buttonUrl2
          }
        }

        menuItems: allWpMenu(filter: { slug: { eq: "mobile" } }) {
          edges {
            node {
              menuItems {
                nodes {
                  parentId
                  label
                  url
                  id
                  childItems {
                    nodes {
                      url
                      label
                      description
                      id
                      acf {
                        linkName
                        sectionName
                        productsLinkName
                        companyLinkName
                        featuresLinkName
                        insightsLinkName
                        solutionsLinkName
                        developersLinkName
                      }

                      childItems {
                        nodes {
                          url
                          label
                          description
                          id
                          acf {
                            sectionName
                            productsLinkName
                            companyLinkName
                            featuresLinkName
                            insightsLinkName
                            solutionsLinkName
                            developersLinkName
                          }
                        }
                      }
                    }
                  }
                  acf {
                    linkName
                    dropdownName
                    sectionName
                    description
                    solutionsLinkName
                    productsLinkName
                    featuresLinkName
                    companyLinkName
                    insightsLinkName
                    developersLinkName
                  }
                }
              }
            }
          }
        }
        illustrationDownload: file(
          relativePath: { eq: "blocks/header/illustration-download.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 210) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <MobileNavigationView
      items={menuItems.edges[0].node.menuItems}
      buttonText={buttonText}
      buttonUrl={buttonUrl}
      buttonText2={buttonText2}
      buttonUrl2={buttonUrl2}
      isOpen={isOpen}
      illustrationDownload={illustrationDownload}
    />
  );
};
