import styled from 'styled-components';

import { color } from 'components/_settings/variables';

export const Inner = styled.div`
  position: relative;
  background-color: ${color.text.tertiary};
  border-radius: 8px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05), 0 8px 15px 0 rgba(0, 0, 0, 0.05);
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 67.5px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    transform: translateY(-100%);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 310px;
  padding-top: 25px;
  transform: translateY(100%);
`;

export const Section = styled.div`
  a {
    margin-bottom: 10px;

    h4 {
      color: #363c4c;
      font-weight: normal;
    }
  }
`;

export const SectionTitle = styled.span`
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  color: #a7aec1;
  text-transform: uppercase;
  margin-bottom: 14px;
  letter-spacing: 1px;
`;
