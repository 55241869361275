import styled from 'styled-components';

import { media } from 'components/_settings/variables';

import CloseIcon from './icons/modal-close.svg';

export const StyledCloseIcon = styled(CloseIcon)`
  width: 10px;

  g {
    stroke: #a7aec1;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  border: none;
  border-radius: 50%;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  outline: none;
  cursor: pointer;

  ${media.sm} {
    top: 15px;
    right: 15px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 40px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;

  ${media.sm} {
    min-height: 300px;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }
`;
