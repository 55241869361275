import * as React from 'react';

import { Wrapper, StyledSelect, Arrow } from './select.styles';

export const Select = ({ value, options, onChange }) => (
  <Wrapper>
    <StyledSelect value={value} onChange={onChange}>
      {options.map(({ value, text }) => (
        <option value={value} key={value}>
          {text}
        </option>
      ))}
    </StyledSelect>
    <Arrow />
  </Wrapper>
);
