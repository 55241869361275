import { Link } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { GlobalContext } from 'components/_settings/global-context';

import { Burger } from './burger';
import {
  Inner,
  LoginLink,
  LoginLinkText,
  LogoWrapper,
  PrimaryNavigation,
  PrimaryNavigationItem,
  PrimaryNavigationLink,
  PrimaryNavigationLinkArrow,
  PrimaryNavigationList,
  SecondaryNavigation,
  StyledButton,
  StyledCompanyDropdown,
  StyledInsightsDropdown,
  StyledFeaturesDropdown,
  StyledProductsDropdown,
  StyledDevelopersDropdown,
  Wrapper,
} from './header.styles';
import Logo from './images/header-logo.svg';

const HeaderView = (props) => {
  const {
    items,
    link,
    buttonText,
    buttonUrl,
    buttonText2,
    buttonUrl2,
    withoutActions,
    withLogoAndRightButtons,
    theme,
    withoutFirstButton,
  } = props;

  const wrapperRef = useRef();

  const { isMobileNavigationOpen, handleOpenMobileNavigation } = React.useContext(GlobalContext);

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1199.98px)',
  });

  const menuItems = items.nodes.filter((item) => item.parentId === null);

  const handleStickyMenu = () => {
    const wrapper = wrapperRef.current;
    // take into account the height of the banner when pinning the menu, if it is displayed on the page
    const bannerHeight = document.querySelector('#announcementBar')?.offsetHeight || 0;
    const offsetTop = wrapper.offsetTop + bannerHeight;

    if (window.pageYOffset > offsetTop) {
      wrapper.classList.add('fixed');
    } else {
      wrapper.classList.remove('fixed');
    }
  };

  useEffect(() => {
    if (wrapperRef?.current) {
      window.addEventListener('scroll', handleStickyMenu);
    }

    return () => {
      window.removeEventListener('scroll', handleStickyMenu);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (isMobileNavigationOpen) {
      wrapper.classList.add('is-open-mobile-navigation');
    } else {
      wrapper.classList.remove('is-open-mobile-navigation');
    }
  }, [isMobileNavigationOpen]);

  return (
    <Wrapper theme={theme} ref={wrapperRef}>
      <Inner>
        <LogoWrapper to="/" alt="WeGift Homepage">
          <Logo />
        </LogoWrapper>

        {!withoutActions && (
          <>
            {!isMobileOrTablet && (
              <>
                {!withLogoAndRightButtons && (
                  <PrimaryNavigation>
                    <PrimaryNavigationList>
                      {menuItems.map(({ id, label, url, acf: { dropdownName }, childItems }) =>
                        childItems.nodes.length > 0 ? (
                          <PrimaryNavigationItem key={id}>
                            <PrimaryNavigationLink
                              as="a"
                              href="#"
                              onClick={(event) => event.preventDefault()}
                            >
                              {label}
                              <PrimaryNavigationLinkArrow />
                            </PrimaryNavigationLink>

                            {dropdownName === 'products' && (
                              <StyledProductsDropdown links={childItems.nodes} />
                            )}
                            {dropdownName === 'features' && (
                              <StyledFeaturesDropdown links={childItems.nodes} />
                            )}
                            {dropdownName === 'company' && (
                              <StyledCompanyDropdown links={childItems.nodes} />
                            )}
                            {dropdownName === 'insights' && (
                              <StyledInsightsDropdown links={childItems.nodes} />
                            )}
                            {dropdownName === 'developers' && (
                              <StyledDevelopersDropdown links={childItems.nodes} />
                            )}
                          </PrimaryNavigationItem>
                        ) : (
                          <PrimaryNavigationItem key={id}>
                            <PrimaryNavigationLink to={url}>{label}</PrimaryNavigationLink>
                          </PrimaryNavigationItem>
                        )
                      )}
                    </PrimaryNavigationList>
                  </PrimaryNavigation>
                )}

                <SecondaryNavigation>
                  <LoginLink to={link.url}>
                    <LoginLinkText>{link.text}</LoginLinkText>
                  </LoginLink>
                  {!withoutFirstButton && (
                    <StyledButton component={Link} theme="fill" to={buttonUrl} arrow={false}>
                      {buttonText}
                    </StyledButton>
                  )}

                  <StyledButton
                    component={Link}
                    theme={withoutFirstButton ? 'fill' : 'bordered'}
                    to={buttonUrl2}
                    arrow={false}
                  >
                    {buttonText2}
                  </StyledButton>
                </SecondaryNavigation>
              </>
            )}
            {isMobileOrTablet && !withLogoAndRightButtons && (
              <Burger isOpen={isMobileNavigationOpen} onClick={handleOpenMobileNavigation} />
            )}
            {isMobileOrTablet && withLogoAndRightButtons && (
              <SecondaryNavigation withLogoAndRightButtons>
                <LoginLink to={link.url}>
                  <LoginLinkText>{link.text}</LoginLinkText>
                </LoginLink>
                {!withoutFirstButton && (
                  <StyledButton component={Link} theme="fill" to={buttonUrl} arrow={false}>
                    {buttonText}
                  </StyledButton>
                )}
                <StyledButton
                  component={Link}
                  theme={withoutFirstButton ? 'fill' : 'bordered'}
                  to={buttonUrl2}
                  arrow={false}
                >
                  {buttonText2}
                </StyledButton>
              </SecondaryNavigation>
            )}
          </>
        )}
      </Inner>
    </Wrapper>
  );
};

export default HeaderView;
