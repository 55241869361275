import styled from 'styled-components';

import { fontSize, color } from 'components/_settings/variables';

import ArrowDownIcon from './icons/arrow-down.svg';

export const Arrow = styled(ArrowDownIcon)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

export const StyledSelect = styled.select`
  width: 100%;
  font-size: ${fontSize.xs};
  color: ${color.text.secondary};
  line-height: 1.8;
  background-color: #fbfbfb;
  border: 1px solid #eaeef2;
  border-radius: 4px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  outline: none;
  -webkit-appearance: none;
`;

export const Wrapper = styled.div`
  position: relative;
`;
