import styled from 'styled-components';

import { color } from 'components/_settings/variables';

import { IconicLink } from '../header-iconic-link';

export const FeaturesSectionLink = styled(IconicLink)`
  flex-basis: 50%;

  &:last-child {
    margin-bottom: 30px;
  }
`;

export const FeaturesSectionLinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
`;

export const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 20px;
`;

export const FeaturesSection = styled.div`
  background-color: #ffffff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-top: 51px;
  padding-right: 35px;
  padding-bottom: 50px;
  padding-left: 35px;
`;

export const Inner = styled.div`
  position: relative;
  border-radius: 8px;
  box-shadow: 0 11px 83px -42px rgba(44, 41, 93, 0.23), 0 0 13px -3px rgba(44, 41, 93, 0.06),
    0 12px 33px -12px rgba(44, 41, 93, 0.5);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 324.5px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    transform: translateY(-100%);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: -260px;
  min-width: 640px;
  padding-top: 25px;
  transform: translateY(100%);
`;
