import styled, { css } from 'styled-components';

import { fontSize, color, transition } from 'components/_settings/variables';
import { rgba } from 'utils';

export const Input = styled.input`
  position: absolute;
  left: -100px;
`;

export const Text = styled.span`
  font-size: ${fontSize.xs};
  color: ${color.text.secondary};
`;

export const Circle = styled.span`
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid ${rgba('#010101', 0.5)};
  border-radius: 50%;
  margin-right: 10px;
  transition: ${transition.base};

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #40d1e7;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);
    transition: ${transition.base};
  }
`;

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${(p) => css`
    ${p.selected &&
    css`
      ${Circle} {
        border-color: #40d1e7;

        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    `}
  `}
`;
