import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { BookDemoView } from './book-demo.view';

export const BookDemo = ({ initialValues }) => {
  const {
    allGfForm: { edges },
  } = useStaticQuery(
    graphql`
      {
        allGfForm {
          edges {
            node {
              formId
              apiURL
              formFields {
                id
                label
                type
                defaultValue
                isRequired
                choices
                conditionalLogic
                description
                cssClass
              }
              confirmations {
                message
              }
              button {
                text
              }
            }
          }
        }
      }
    `
  );

  return <BookDemoView formsData={edges} initialValues={initialValues} />;
};
