import { motion } from 'framer-motion';
import styled from 'styled-components';

import { color } from 'components/_settings/variables';

export const Line = styled(motion.span)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: ${color.text.primary};

  &:nth-child(2) {
    top: 10px;
  }

  &:nth-child(3) {
    top: auto;
    bottom: 0;
  }

  &:nth-child(4),
  &:nth-child(5) {
    top: 10px;
  }
`;

export const Wrapper = styled(motion.button)`
  position: relative;
  height: 24px;
  width: 32px;
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  cursor: pointer;
  outline: none;
`;
