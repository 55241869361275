import * as React from 'react';

import { FormGenerator } from 'components/shared/form-generator';

import { SuccessModal } from '../success-modal';

export const BookDemoView = ({ formsData, initialValues }) => {
  const [successMessage, setSuccessMessage] = React.useState(null);

  const BOOK_DEMO_FORM_ID = 4;
  const bookDemoFormData = formsData.find(({ node: { formId } }) => formId === BOOK_DEMO_FORM_ID);

  return (
    <>
      <FormGenerator
        data={bookDemoFormData.node}
        initialValues={initialValues}
        onSuccessfulFormSubmit={setSuccessMessage.bind(
          null,
          bookDemoFormData.node.confirmations[0].message
        )}
      />

      {successMessage && (
        <SuccessModal message={successMessage} onClose={setSuccessMessage.bind(null, null)} />
      )}
    </>
  );
};
