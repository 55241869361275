import styled from 'styled-components';

import Logo from 'assets/images/logo.svg';
import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Message = styled.p`
  max-width: 400px;
  font-size: ${fontSize.xs};
  line-height: 1.4;
  text-align: center;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  max-width: 340px;
  font-weight: 500;
  text-align: center;
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;

  ${media.sm} {
    margin-bottom: 15px;
  }
`;

export const StyledLogo = styled(Logo)`
  width: 45px;
  fill: ${color.primary};

  ${media.sm} {
    width: 35px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #e2fbff;
  border-radius: 50%;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;

  ${media.sm} {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
`;
