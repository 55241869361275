import * as React from 'react';
import ReactModal from 'react-modal';

import { Wrapper, CloseButton, StyledCloseIcon } from './modal.styles';

export const Modal = ({ className: classNameAdditional, children, onClose, isOpen }) => (
  <ReactModal isOpen={isOpen} onRequestClose={onClose}>
    <Wrapper className={classNameAdditional}>
      <div>{children}</div>
      <CloseButton onClick={onClose}>
        <StyledCloseIcon />
      </CloseButton>
    </Wrapper>
  </ReactModal>
);

Modal.defaultProps = {
  isOpen: true,
};
