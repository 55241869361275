import * as React from 'react';
import { Helmet } from 'react-helmet';

export const SEO = ({ title, description, ogImage }) => (
  <Helmet title={title}>
    <html lang="en" />
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {ogImage && <meta property="og:image" content={ogImage} />}
  </Helmet>
);
