import * as React from 'react';

import { Wrapper, Box, StyledCheckIcon, Text, Input } from './checkbox.styles';

export const Checkbox = ({ className, name, value, text, selected, invalid, onChange }) => (
  <Wrapper className={className} selected={selected} invalid={invalid}>
    <Box>
      <StyledCheckIcon />
    </Box>
    <Text>{text}</Text>
    <Input name={name} type="checkbox" value={value} checked={selected} onChange={onChange} />
  </Wrapper>
);
