import { breakpoint } from './breakpoint';

export const media = {
  xxs: `@media only screen and (max-width: ${breakpoint.xxs}px)`,
  xs: `@media only screen and (max-width: ${breakpoint.xs}px)`,
  sm: `@media only screen and (max-width: ${breakpoint.sm}px)`,
  md: `@media only screen and (max-width: ${breakpoint.md}px)`,
  lg: `@media only screen and (max-width: ${breakpoint.lg}px)`,
  xl: `@media only screen and (max-width: ${breakpoint.xl}px)`,
};
