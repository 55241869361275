import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import HeaderView from './header.view';

export const Header = ({ withoutActions, withLogoAndRightButtons, withoutFirstButton, theme }) => {
  const {
    wpSharedBlock: {
      acf: {
        headerLinkText,
        headerLinkUrl,
        headerButtonText,
        headerButtonUrl,
        headerButtonText2,
        headerButtonUrl2,
      },
    },
    menuItems,
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "header" }) {
          acf {
            headerLinkUrl
            headerLinkText
            headerButtonText
            headerButtonUrl
            headerButtonText2
            headerButtonUrl2
          }
        }
        menuItems: allWpMenu(filter: { slug: { eq: "header" } }) {
          edges {
            node {
              menuItems {
                nodes {
                  parentId
                  label
                  url
                  id
                  childItems {
                    nodes {
                      url
                      label
                      description
                      id
                      acf {
                        sectionName
                        productsLinkName
                        featuresLinkName
                        companyLinkName
                        insightsLinkName
                        solutionsLinkName
                        developersLinkName
                      }

                      childItems {
                        nodes {
                          url
                          label
                          description
                          id
                          acf {
                            sectionName
                            productsLinkName
                            featuresLinkName
                            companyLinkName
                            insightsLinkName
                            solutionsLinkName
                            developersLinkName
                          }
                        }
                      }
                    }
                  }
                  acf {
                    dropdownName
                    sectionName
                    description
                    solutionsLinkName
                    featuresLinkName
                    productsLinkName
                    companyLinkName
                    insightsLinkName
                    developersLinkName
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const link = {
    text: headerLinkText,
    url: headerLinkUrl,
  };

  return (
    <HeaderView
      theme={theme}
      items={menuItems.edges[0].node.menuItems}
      link={link}
      buttonText={headerButtonText}
      buttonUrl={headerButtonUrl}
      buttonText2={headerButtonText2}
      buttonUrl2={headerButtonUrl2}
      withoutActions={withoutActions}
      withLogoAndRightButtons={withLogoAndRightButtons}
      withoutFirstButton={withoutFirstButton}
    />
  );
};
