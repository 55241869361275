import * as React from 'react';

import { SuccessModalView } from './success-modal.view';

function parseMessage(message) {
  const html = document.createElement('html');
  html.innerHTML = message;

  const h1 = html.getElementsByTagName('h1')[0];
  const p = html.getElementsByTagName('p')[0];

  const title = h1 ? h1.innerHTML : message;
  const description = p ? p.innerHTML : null;
  return [title, description];
}

export const SuccessModal = ({ message, onClose }) => {
  const [title, description] = parseMessage(message);

  return <SuccessModalView title={title} description={description} onClose={onClose} />;
};
