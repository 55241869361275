import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';

export const Wrapper = styled.div`
  .rc-slider {
    padding-top: 16px;
    padding-bottom: 39px;

    ${media.xs} {
      padding-top: 39px;
    }
  }

  .rc-slider-rail,
  .rc-slider-track {
    height: 3px;
    background-color: #40d1e7;
  }

  .rc-slider-dot {
    width: 8px;
    height: 8px;
    background-color: #02bad8;
    border: none;
    margin-left: -4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-left: -8px;
    }
  }

  .rc-slider-handle,
  .rc-slider-handle-click-focused {
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: 0 2px 4px 0 #006070, 0 0 0 7px rgba(198, 236, 242, 0.7);
    margin-top: -10px;
    margin-left: -10px;

    &:active,
    &:focus {
      box-shadow: 0 2px 4px 0 #006070, 0 0 0 7px rgba(198, 236, 242, 0.7);
    }

    &[aria-valuenow='0'] {
      margin-left: 0;
    }

    &[aria-valuenow='100'] {
      margin-left: -20px;
    }
  }

  .rc-slider-mark {
    top: 40px;

    ${media.xs} {
      top: 63px;
    }
  }

  .rc-slider-mark-text {
    font-size: 12px;
    white-space: nowrap;
    color: ${color.text.secondary};

    &:first-child {
      transform: none !important;
    }

    &:nth-of-type(2n) {
      ${media.xs} {
        transform: translateY(-450%) translateX(-50%) !important;

        &:last-child {
          transform: translateY(-450%) translateX(-100%) !important;
        }
      }
    }

    &:last-child {
      transform: translateX(-100%) !important;
    }
  }

  .rc-slider-mark-text-active {
    font-weight: 700;
  }
`;
