import styled, { css } from 'styled-components';

import { fontSize, color, transition } from 'components/_settings/variables';

export const TextField = styled.input`
  width: 100%;
  font-size: ${fontSize.xs};
  color: ${color.text.secondary};
  line-height: 1.8;
  background-color: #fbfbfb;
  border: 1px solid #eaeef2;
  border-radius: 4px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  caret-color: #40d1e7;
  resize: vertical;
  outline: none;
  transition: border-color ${transition.base}, box-shadow ${transition.base},
    caret-color ${transition.base};
  -webkit-appearance: none;

  &:focus {
    border-color: #40d1e7;
    box-shadow: 0 0 0 1px #40d1e7;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${color.text.secondary};
    transition: background-color 10000s, border-color ${transition.base},
      box-shadow ${transition.base}, caret-color ${transition.base};
  }

  ${(p) => css`
    ${p.invalid &&
    css`
      border-color: #fb5e62;
      box-shadow: 0 0 0 1px #fb5e62;
      caret-color: #fb5e62;

      &:focus {
        border-color: #fb5e62;
        box-shadow: 0 0 0 1px #fb5e62;
      }
    `}
  `}
`;
