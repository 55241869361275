import * as React from 'react';

import { Wrapper, IconWrapper, Content, Title, Description } from './header-iconic-link.styles';
import CompanyLinkIcon1 from './icons/header-iconic-link-company-link-icon1.url.svg';
import CompanyLinkIcon2 from './icons/header-iconic-link-company-link-icon2.url.svg';
import DevelopersLinkIcon1 from './icons/header-iconic-link-developers-icon1.url.svg';
import DevelopersLinkIcon2 from './icons/header-iconic-link-developers-icon2.url.svg';
import DevelopersLinkIcon3 from './icons/header-iconic-link-developers-icon3.url.svg';
import FeaturesLinkIcon1 from './icons/header-iconic-link-features-link-icon1.url.svg';
import FeaturesLinkIcon2 from './icons/header-iconic-link-features-link-icon2.url.svg';
import FeaturesLinkIcon3 from './icons/header-iconic-link-features-link-icon3.url.svg';
import FeaturesLinkIcon4 from './icons/header-iconic-link-features-link-icon4.url.svg';
import InsightsLinkIcon1 from './icons/header-iconic-link-insights-link-icon1.url.svg';
import InsightsLinkIcon2 from './icons/header-iconic-link-insights-link-icon2.url.svg';
import InsightsLinkIcon3 from './icons/header-iconic-link-insights-link-icon3.url.svg';
import ProductsLinkIcon1 from './icons/header-iconic-link-products-link-icon1.url.svg';
import ProductsLinkIcon2 from './icons/header-iconic-link-products-link-icon2.url.svg';
import ProductsLinkIcon3 from './icons/header-iconic-link-products-link-icon3.url.svg';
import ProductsLinkIcon4 from './icons/header-iconic-link-products-link-icon4.url.svg';
import ProductsLinkIcon5 from './icons/header-iconic-link-products-link-icon5.url.svg';
import ProductsLinkIcon6 from './icons/header-iconic-link-products-link-icon6.url.svg';
import SolutionsLinkIcon1 from './icons/header-iconic-link-solutions-icon1.url.svg';
import SolutionsLinkIcon3 from './icons/header-iconic-link-solutions-icon3.url.svg';
import SolutionsLinkIcon4 from './icons/header-iconic-link-solutions-icon4.url.svg';
import SolutionsLinkIcon5 from './icons/header-iconic-link-solutions-icon5.url.svg';
import SolutionsLinkIcon6 from './icons/header-iconic-link-solutions-icon6.url.svg';
import SolutionsLinkIcon7 from './icons/header-iconic-link-solutions-icon7.url.svg';
import SolutionsLinkIcon8 from './icons/header-iconic-link-solutions-icon8.url.svg';

export const IconicLink = ({ className, title, description, to, linkName }) => {
  const linkIcons = {
    'employee-benefits': SolutionsLinkIcon1,
    acquisition: SolutionsLinkIcon4,
    goodwill: SolutionsLinkIcon3,
    'service-providers': SolutionsLinkIcon5,
    'brand-sales': SolutionsLinkIcon6,
    'employee-rewards': SolutionsLinkIcon7,
    api: SolutionsLinkIcon8,

    'bulk-gift-cards': FeaturesLinkIcon1,
    'gift-card-api': FeaturesLinkIcon2,
    'visa-card': FeaturesLinkIcon3,
    'international-reach': FeaturesLinkIcon4,

    connect: ProductsLinkIcon1,
    drive: ProductsLinkIcon2,
    link: ProductsLinkIcon3,
    wrap: ProductsLinkIcon4,
    flow: ProductsLinkIcon5,
    cryptocurrency: ProductsLinkIcon6,

    about: CompanyLinkIcon1,
    careers: CompanyLinkIcon2,

    blog: InsightsLinkIcon1,
    customer: InsightsLinkIcon2,
    video: InsightsLinkIcon3,

    'api-docs': DevelopersLinkIcon1,
    'get-started': DevelopersLinkIcon2,
    'api-refs': DevelopersLinkIcon3,
  };

  return (
    <Wrapper className={className} to={to}>
      {linkName && (
        <IconWrapper>
          <img src={linkIcons[linkName]} alt="" width={40} height={40} />
        </IconWrapper>
      )}

      <Content>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Content>
    </Wrapper>
  );
};
