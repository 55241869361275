import styled, { css } from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Link } from 'components/shared/link';

export const ButtonWrapper = styled.div`
  border-top: 1px solid #eaeef2;
  padding-top: 25px;

  & > * {
    width: 100%;

    span {
      &:first-child {
        width: 100%;
      }
    }
  }
`;

export const SectionLink = styled(Link)`
  flex-basis: 50%;
  font-size: ${fontSize.xs};
  text-decoration: none;
  color: #2b303d;
  margin-bottom: 10px;
  transition: ${transition.base};

  &:hover {
    color: ${color.primary};
  }

  ${(p) => css`
    ${p.direction === 'column' &&
    css`
      flex-basis: 100%;
    `}
  `}
`;

export const StyledButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin-top: 15px;

  span {
    width: 100%;
    padding: 15px 26px;
  }
`;

export const InsightsLinkIllustrationWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
`;

export const InsightsLinkDescription = styled.p`
  font-size: 12px;
  color: #4b536a;
  margin-top: 0;
  margin-bottom: 0;
`;

export const InsightsLinkText = styled.span`
  display: block;
  max-width: 200px;
  font-size: ${fontSize.xs};
  line-height: 1.4;
  color: #2b303d;
  margin-bottom: 10px;
  transition: ${transition.base};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: ${color.primary};
  }
`;

export const InsightsLinkContent = styled.div`
  width: 100%;
`;

export const InsightsLinkIconWrapper = styled.div`
  flex-shrink: 0;
  font-size: 0;
  margin-right: 20px;

  svg {
    width: 40px;
  }
`;

export const InsightsLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  max-width: 100%;
`;

export const SectionLinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
`;

export const FeaturesLinkText = styled.span`
  max-width: 200px;
  font-size: ${fontSize.xs};
  line-height: 1.4;
  color: #2b303d;
  transition: ${transition.base};

  &:hover {
    color: ${color.primary};
  }
`;

export const FeaturesLinkIconWrapper = styled.div`
  flex-shrink: 0;
  font-size: 0;
  margin-right: 20px;

  svg {
    width: 40px;
  }
`;

export const FeaturesLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const SolutionsLinkText = styled.span`
  max-width: 200px;
  font-size: ${fontSize.xs};
  line-height: 1.4;
  color: #2b303d;
  transition: ${transition.base};

  &:hover {
    color: ${color.primary};
  }
`;

export const SolutionsLinkIconWrapper = styled.div`
  flex-shrink: 0;
  font-size: 0;
  margin-right: 20px;

  svg {
    width: 40px;
  }

  img {
    height: auto;
  }
`;

export const SolutionsLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const SectionTitle = styled.h3`
  display: inline-block;
  font-size: 10px;
  letter-spacing: 0.83px;
  text-transform: uppercase;
  color: #a7aec1;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const SectionInner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 25px;

  ${media.sm} {
    display: flex;
    flex-direction: column;
  }
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;

export const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: ${color.text.tertiary};
  padding-top: 90px;
  padding-bottom: 35px;
  opacity: 0;
  visibility: hidden;
  transition: ${transition.base};
  overflow-y: auto;

  ${(p) => css`
    ${p.open &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  `}
`;
