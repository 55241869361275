import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useEffect } from 'react';

import { getCookie, setCookie } from 'utils';

import { GlobalModalView } from './global-modal.view';

export const GlobalModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const cookiesName = 'dismissed-campaigns';

  const {
    wpSharedBlock: {
      acf: {
        globalModalActive,
        globalModalTitle,
        globalModalContent,
        globalModalImage,
        globalModalShowImage,
        globalModalButtonText,
        globalModalButtonUrl,
        globalModalCampaignId,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "global-modal" }) {
          acf {
            globalModalActive
            globalModalTitle
            globalModalContent
            globalModalImage {
              id
              altText
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            globalModalShowImage
            globalModalButtonText
            globalModalButtonUrl
            globalModalCampaignId
          }
        }
      }
    `
  );

  useEffect(() => {
    if (getCookie(cookiesName) && getCookie(cookiesName).includes(globalModalCampaignId)) {
      setIsOpen(false);
    }
  }, []);

  const handleButtonClose = () => {
    setIsOpen(false);

    setCookie(cookiesName, globalModalCampaignId);
  };

  return (
    <>
      {globalModalActive && (
        <GlobalModalView
          title={globalModalTitle}
          description={globalModalContent}
          image={globalModalImage}
          showImage={globalModalShowImage}
          buttonText={globalModalButtonText}
          buttonUrl={globalModalButtonUrl}
          isOpen={isOpen}
          onClose={handleButtonClose}
        />
      )}
    </>
  );
};
