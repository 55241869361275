import styled from 'styled-components';

import { fontSize, color, transition } from 'components/_settings/variables';
import { Link } from 'components/shared/link';

export const Description = styled.p`
  font-size: 12px;
  color: #4b536a;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const Title = styled.h4`
  font-size: ${fontSize.xs};
  font-weight: 500;
  color: #2b303d;
  margin-top: 0;
  margin-bottom: 0;
  transition: ${transition.base};
`;

export const Content = styled.div``;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  font-size: 0;
  margin-right: 20px;

  svg {
    width: 40px;
  }
`;

export const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 30px;

  &:hover {
    ${Title} {
      color: ${color.primary};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
