import styled, { css, keyframes } from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Field } from 'components/shared/field';
import { rgba } from 'utils';

export const loadingDotsAnimation = keyframes`
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 4px 0 0 rgba(0, 0, 0, 0), 8px 0 0 rgba(0, 0, 0, 0);
  }
  
  40% {
    color: #ffffff;
    text-shadow: 4px 0 0 rgba(0, 0, 0, 0), 8px 0 0 rgba(0, 0, 0, 0);
  }
  
  60% {
    text-shadow: 4px 0 0 #ffffff, 8px 0 0 rgba(0, 0, 0, 0);
  }
  
  80%,
  100% {
    text-shadow: 4px 0 0 #ffffff, 8px 0 0 #ffffff;
  }
`;

export const Button = styled.button`
  width: 100%;
  font-size: ${fontSize.sm};
  color: ${color.text.tertiary};
  background-color: ${color.primary};
  border: none;
  border-radius: 4px;
  padding-top: 17.5px;
  padding-right: 45px;
  padding-bottom: 17.5px;
  padding-left: 45px;
  outline: none;
  cursor: pointer;
  transition: ${transition.base};

  &:hover {
    background-color: ${rgba(color.primary, 0.8)};
  }

  &:focus,
  &:active {
    background-color: ${color.primary};
  }

  ${(p) => css`
    ${p.submitting &&
    css`
      &:after {
        content: '.';
        animation: ${loadingDotsAnimation} 1s steps(5, end) infinite;
      }
    `}
  `}
`;

export const StyledField = styled(Field)``;

export const FieldsWrapper = styled.div`
  margin-bottom: 25px;

  ${media.xs} {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(p) => css`
    ${p.doable &&
    css`
      display: flex;
      margin-right: -20px;
      margin-left: -20px;

      ${media.sm} {
        display: block;
        margin-right: 0;
        margin-left: 0;
      }

      ${StyledField} {
        flex-basis: calc(50% - 40px);
        max-width: calc(50% - 40px);
        margin-right: 20px;
        margin-left: 20px;

        ${media.sm} {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          margin-left: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    `}
  `}
`;

export const Wrapper = styled.form`
  ${(p) => css`
    ${p.isHidden &&
    css`
      display: none;
    `}
  `}
`;
