import * as React from 'react';

import { Link } from 'components/shared/link';

import { IconicLink } from '../header-iconic-link';

import {
  Wrapper,
  Inner,
  Content,
  DownloadWrapper,
  LinksWrapper,
  IllustrationWrapper,
  StyledButton,
  Section,
  SectionTitle,
} from './header-insights-dropdown.styles';
import illustrationDownload2 from './images/illustration-download.url.svg';

export const InsightsDropdown = ({ className, links }) => (
    <Wrapper className={className}>
      <Inner>
        <Content>
          <LinksWrapper>
            {links.map(
              ({
                id,
                label,
                description,
                url,
                acf: { insightsLinkName },
                childItems: children,
              }) => {
                if (insightsLinkName !== 'download') {
                  return children.nodes.length > 0 ? (
                    <Section>
                      <SectionTitle>{label}</SectionTitle>
                      {children.nodes.map(({ id, label, description, url }) => (
                        <IconicLink
                          title={label}
                          description={description}
                          to={url}
                          linkName={false}
                          key={id}
                        />
                      ))}
                    </Section>
                  ) : (
                    <IconicLink
                      title={label}
                      description={description}
                      to={url}
                      linkName={insightsLinkName}
                      key={id}
                    />
                  );
                }
              }
            )}
          </LinksWrapper>
          <DownloadWrapper>
            {links.map(({ id, label, description, url, acf: { insightsLinkName } }) => {
              if (insightsLinkName === 'download') {
                return (
                  <>
                    <IllustrationWrapper>
                      <img
                        src={illustrationDownload2}
                        height={350}
                        width={600}
                        alt=""
                        loading="eager"
                      />
                    </IllustrationWrapper>
                    <IconicLink
                      title={label}
                      description={description}
                      to={url}
                      linkName={false}
                      key={id}
                    />

                    <StyledButton to={url} component={Link} arrow={false}>
                      Read now
                    </StyledButton>
                  </>
                );
              }
            })}
          </DownloadWrapper>
        </Content>
      </Inner>
    </Wrapper>
  );
