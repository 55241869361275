import { Link } from 'gatsby';
import * as React from 'react';

import { getFluidImage } from 'utils';

import { StyledModal, StyledTitle, Description, Image, StyledButton } from './global-modal.styles';

export const GlobalModalView = ({
  title,
  description,
  image,
  showImage,
  buttonText,
  buttonUrl,
  isOpen,
  onClose,
}) => (
  <StyledModal isOpen={isOpen} showImage={showImage} onClose={onClose}>
    {showImage && <Image fluid={getFluidImage(image.localFile)} alt={image.altText} />}
    <StyledTitle>{title}</StyledTitle>
    <Description dangerouslySetInnerHTML={{ __html: description }} />
    {buttonText && buttonUrl && (
      <StyledButton component={Link} theme="fill" to={buttonUrl}>
        {buttonText}
      </StyledButton>
    )}
  </StyledModal>
);
