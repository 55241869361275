import * as React from 'react';

import { IconicLink } from '../header-iconic-link';

import { Wrapper, Inner, SolutionsSection } from './header-products-dropdown.styles';

export const ProductsDropdown = ({ className, links }) => {
  const solutionsLinks = links.filter(({ acf: { sectionName } }) => sectionName === 'solutions');

  return (
    <Wrapper className={className}>
      <Inner>
        <SolutionsSection>
          {solutionsLinks.map(({ id, label, description, acf: { solutionsLinkName }, url }) => (
            <IconicLink
              title={label}
              description={description}
              to={url}
              linkName={solutionsLinkName}
              key={id}
            />
          ))}
        </SolutionsSection>
      </Inner>
    </Wrapper>
  );
};
