import * as React from 'react';
import styled, { css } from 'styled-components';

import { fontSize, color, transition } from 'components/_settings/variables';
import { rgba } from 'utils';

export const ArrowWrapper = styled.span`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  background-color: ${rgba(color.primary, 0.5)};
  border-radius: 0 4px 4px 0;
  svg {
    transition: ${transition.expo};
  }
  g > g {
    transition: ${transition.base};
  }
`;

export const TextWrapper = styled.span`
  font-size: ${fontSize.xs};
  font-weight: 700;
  line-height: 1;
  background-color: ${color.primary};
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-left: 30px;
  border-radius: 4px;
  transition: ${transition.base};
`;

export const Wrapper = styled(({ loading, children, ...rest }) => (
  // eslint-disable-next-line react/button-has-type
  <button {...rest}>{children}</button>
))`
  display: inline-flex;
  align-items: stretch;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none; // TODO: Accessibility is forsaken
  cursor: pointer;
  transition: ${transition.base};
  color: ${color.text.tertiary};
  &:hover {
    transform: scale(1.02);
    ${ArrowWrapper} svg {
      transform: translateX(3px);
    }
  }
  &:active,
  &:focus {
    opacity: 1;
  }
  ${(p) => css`
    ${p.arrow &&
    css`
      ${TextWrapper} {
        border-radius: 4px 0 0 4px;
      }
    `}
  `}
  ${(p) => css`
    ${p.theme === 'fill' &&
    css`
      display: inline-flex;

      ${TextWrapper}, ${ArrowWrapper} {
        background-color: ${color.primary};
        box-shadow: 0 10px 20px rgba(0, 193, 222, 0.247104);
      }
    `}
    ${p.theme === 'fill-orange' &&
    css`
      ${TextWrapper}, ${ArrowWrapper} {
        background-color: ${color.text.orange};
        box-shadow: 0 10px 20px 0 ${rgba(color.text.orange, 0.25)};
      }
    `}
    ${p.theme === 'fill-purple' &&
    css`
      ${TextWrapper}, ${ArrowWrapper} {
        background-color: ${color.text.purple};
        box-shadow: 0 10px 20px 0 ${rgba(color.text.purple, 0.25)};
      }
    `}
    ${p.theme === 'light' &&
    css`
      ${TextWrapper} {
        background-color: ${rgba(color.primary, 0.75)};
      }
    `}

    ${p.theme === 'low-light' &&
    css`
      color: ${color.primary};
      background-color: #f7feff;

      ${TextWrapper} {
        background-color: ${rgba(color.primary, 0.75)};
      }

      ${TextWrapper} {
        background-color: rgba(255, 255, 255, 0.15);
      }

      ${ArrowWrapper} {
        background-color: ${rgba(color.primary, 0.2)};
      }
    `}
    
    ${p.theme === 'text' &&
    css`
      ${TextWrapper} {
        padding-right: 0;
      }
      ${TextWrapper}, ${ArrowWrapper} {
        background-color: transparent;
        color: ${color.primary};
      }
    `}
    
    ${p.theme === 'bordered' &&
    css`
      border: 2px solid ${color.primary};
      border-radius: 4px;
      border: 2px solid ${color.primary};
      &:hover {
        background-color: ${color.primary};

        ${TextWrapper} {
          color: ${color.text.tertiary};
        }

        ${ArrowWrapper} {
          g > g {
            stroke: ${color.text.tertiary};
          }
        }
      }

      ${TextWrapper} {
        background-color: transparent;
        color: ${color.primary};
        padding-top: 18px;
        padding-bottom: 18px;
      }

      ${(p) => css`
        ${p.arrow &&
        css`
          ${TextWrapper} {
            padding-right: 0;
          }

          ${ArrowWrapper} {
            height: 50px;
            background-color: transparent;

            g > g {
              stroke: ${color.primary};
            }
          }
        `}
      `}
    `}
    
    ${p.loading &&
    css`
      ${TextWrapper} {
        color: transparent;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -10px 0 0 -10px;
          width: 20px;
          height: 20px;
          border: 1px solid #fff;
          border-bottom: none;
          border-radius: 50%;
          animation: spin 2s;

          ${p.theme === 'text' &&
          css`
            border-color: ${color.primary};
          `}
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    `}
  `}
`;
