import styled, { css } from 'styled-components';

import { color, fontSize, transition } from 'components/_settings/variables';
import { Link } from 'components/shared/link';

import CloseIcon from './icons/close.svg';

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: ${color.text.primary};
  padding-top: 14px;
  padding-bottom: 14px;
  z-index: 1;
  transform: translateY(-100%);
  transition: ${transition.base};

  ${(p) => css`
    ${p.visible &&
    css`
      position: relative;
      transform: translateY(0);
    `}
  `}
`;

export const Text = styled.p`
  font-size: ${fontSize.sm};
  line-height: 28px;
  color: ${color.text.tertiary};
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 60px;
  padding-right: 60px;
`;

export const StyledLink = styled(Link)`
  color: ${color.primary};
  text-decoration: none;
  white-space: nowrap;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 13px;
  right: 22px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  outline: none;
  cursor: pointer;
`;

export const StyledCloseIcon = styled(CloseIcon)``;
