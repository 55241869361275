import styled, { css } from 'styled-components';

import { fontSize, color, transition } from 'components/_settings/variables';
import { CheckIcon } from 'components/icons';
import { rgba } from 'utils';

export const Input = styled.input`
  position: absolute;
  left: -100px;
`;

export const Text = styled.span`
  font-size: ${fontSize.xs};
  color: ${color.text.secondary};
`;

export const StyledCheckIcon = styled(CheckIcon)`
  opacity: 0;
  visibility: hidden;
  transition: ${transition.base};
`;

export const Box = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid ${rgba('#010101', 0.5)};
  border-radius: 2px;
  margin-right: 10px;
  transition: ${transition.base};
`;

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${(p) => css`
    ${p.selected &&
    css`
      ${Box} {
        background-color: #40d1e7;
        border-color: #40d1e7;

        ${StyledCheckIcon} {
          opacity: 1;
          visibility: visible;
        }
      }
    `}

    ${p.invalid &&
    css`
      ${Box} {
        border-color: #fb5e62;
      }
    `}
  `}
`;
