import styled, { css } from 'styled-components';

import { color, media } from 'components/_settings/variables';

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 500;
  line-height: 1.3;
  color: ${color.text.primary};
  margin-top: 0;
  margin-bottom: 0;
  ${media.sm} {
    font-size: 24px;
  }
  ${(p) => css`
    ${p.size === 'xxl' &&
    css`
      font-size: 60px;
      line-height: 1.2;
      ${media.sm} {
        font-size: 38px;
      }
    `}

    ${p.size === 'xl' &&
    css`
      font-size: 52px;
      line-height: 1.25;
      ${media.sm} {
        font-size: 32px;
      }
    `}
    
    ${p.size === 'lg' &&
    css`
      font-size: 42px;
      line-height: 1.25;
      ${media.sm} {
        font-size: 28px;
      }
    `}
    
    ${p.size === 'sm' &&
    css`
      font-size: 24px;
      line-height: 1.4;
      ${media.sm} {
        font-size: 20px;
      }
    `}
    
    ${p.size === 'xs' &&
    css`
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
      ${media.sm} {
        font-size: 16px;
      }
    `}
    
    ${p.theme === 'light' &&
    css`
      color: #e2c8ff;
    `}
  `}
`;
