import styled, { css } from 'styled-components';

import { color, transition, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Link } from 'components/shared/link';

import { Line as BurgerLine } from './burger/header-burger.styles';
import { CompanyDropdown } from './company-dropdown';
import { DevelopersDropdown } from './developers-dropdown';
import { FeaturesDropdown } from './features-dropdown';
import ArrowDown from './icons/arrow-down.svg';
import { InsightsDropdown } from './insights-dropdown';
import { ProductsDropdown } from './products-dropdown';

export const LoginLinkText = styled.span`
  margin-right: 12px;
`;

export const LoginLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 15px;
  text-decoration: none;
  color: ${color.text.primary};
  margin-right: 5px;
  transition: color ${transition.base};

  &:hover {
    color: ${color.primary};
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 16px;
`;

export const SecondaryNavigation = styled.nav`
  display: flex;

  ${(p) => css`
    ${p.withLogoAndRightButtons &&
    css`
      ${LoginLink} {
        ${media.xxs} {
          margin-right: 10px;

          span {
            margin-right: 0;
          }
        }
      }

      ${StyledButton} {
        ${media.xxs} {
          span {
            padding: 20px 10px;
            font-size: 12px;
          }
        }
      }
    `}
  `}
`;

const StyledDropdown = (Dropdown) => styled(Dropdown)`
  /* keep pointer events hidden when not hovering to avoid stuttering glitch */
  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, calc(100% - 10px), 0);
  visibility: hidden;
  transition: ${transition.base};
`;

export const StyledInsightsDropdown = StyledDropdown(InsightsDropdown);
export const StyledCompanyDropdown = StyledDropdown(CompanyDropdown);
export const StyledProductsDropdown = StyledDropdown(ProductsDropdown);
export const StyledFeaturesDropdown = StyledDropdown(FeaturesDropdown);
export const StyledDevelopersDropdown = StyledDropdown(DevelopersDropdown);

export const PrimaryNavigationLinkArrow = styled(ArrowDown)`
  margin-top: 2.5px;
  margin-left: 7.5px;
  color: ${color.text.primary};
  transition: ${transition.swift};

  path {
    transition: ${transition.base};
  }
`;

export const PrimaryNavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 15px;
  text-decoration: none;
  color: ${color.text.primary};
  transition: ${transition.base};
`;

export const PrimaryNavigationItem = styled.li`
  position: relative;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${PrimaryNavigationLink} {
      color: ${color.primary};
      ${PrimaryNavigationLinkArrow} {
        transform: translateY(1px);
        color: ${color.primary};
      }
    }
    ${StyledProductsDropdown}, ${StyledFeaturesDropdown}, ${StyledCompanyDropdown}, ${StyledInsightsDropdown}, ${StyledDevelopersDropdown} {
      pointer-events: all;
      transform: translateY(100%);
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const PrimaryNavigationList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const PrimaryNavigation = styled.nav``;

export const LogoWrapper = styled(Link)`
  font-size: 0;
  color: ${color.primary};
`;

export const Inner = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
`;

export const Wrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  transition: all ${transition.base};

  &.fixed,
  &.is-open-mobile-navigation {
    position: fixed;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: ${color.text.tertiary};
    box-shadow: 0px 4px 4px rgba(44, 41, 93, 0.04);

    ${media.md} {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    ${StyledButton} {
      span {
        box-shadow: none;
      }
    }
  }

  ${media.sm} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${(p) => css`
    ${p.theme === 'light' &&
    css`
      &:not(.fixed):not(.is-open-mobile-navigation) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        ${LogoWrapper},
        ${PrimaryNavigationLink},
      ${PrimaryNavigationLinkArrow},
      ${LoginLink} {
          color: ${color.text.tertiary};
        }

        ${PrimaryNavigationLink}:hover {
          color: ${color.primary};

          ${PrimaryNavigationLinkArrow} {
            color: ${color.primary};
          }
        }

        ${LoginLink}:hover {
          color: ${color.primary};
        }

        ${BurgerLine} {
          background-color: ${color.text.tertiary};
        }
      }
    `}
  `}
`;
