import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useEffect } from 'react';

import { getCookie, setCookie } from 'utils';

import { AnnouncementBarView } from './announcement-bar.view';

export const AnnouncementBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const cookiesName = 'dismissed-bars';

  const {
    wpSharedBlock: {
      acf: {
        announcementBarActive,
        announcementBarCampaignId,
        announcementBarLinkText,
        announcementBarLinkUrl,
        announcementBarText,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "announcement-bar" }) {
          acf {
            announcementBarActive
            announcementBarCampaignId
            announcementBarLinkText
            announcementBarLinkUrl
            announcementBarText
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!(getCookie(cookiesName) && getCookie(cookiesName).includes(announcementBarCampaignId))) {
      setIsVisible(true);
    }
  }, []);

  const handleButtonClose = () => {
    setIsVisible(false);
    setCookie(cookiesName, announcementBarCampaignId);
  };

  return (
    <>
      {announcementBarActive && (
        <AnnouncementBarView
          text={announcementBarText}
          linkUrl={announcementBarLinkUrl}
          linkText={announcementBarLinkText}
          handleButtonClose={handleButtonClose}
          visible={isVisible}
        />
      )}
    </>
  );
};
