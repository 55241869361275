import CryptoJS from 'crypto-js';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { FREE_EMAIL_PROVIDER_DOMAINS } from 'constants/free-email-provider-domains';
import { getTransformedSliderChoices } from 'utils';

import { FormGeneratorView } from './form-generator.view';

export function submissionParams(method, route) {
  const key = process.env.GATSBY_GRAVITY_FORMS_KEY;
  const secret = process.env.GATSBY_GRAVITY_FORMS_SECRET;

  const currentDate = new Date();
  const expiration = 3600; // 1 hour
  const unixTime = parseInt(currentDate.getTime() / 1000, 10);
  const futureUnixTime = unixTime + expiration;

  const stringToSign = `${key}:${method}:${route}:${futureUnixTime}`;
  const signature = encodeURIComponent(
    CryptoJS.HmacSHA1(stringToSign, secret).toString(CryptoJS.enc.Base64)
  );

  return `api_key=${key}&signature=${signature}&expires=${futureUnixTime}`;
}

export const FormGenerator = withFormik({
  displayName: 'FormGenerator',
  enableReinitialize: true,
  mapPropsToValues: ({ data: { formFields }, initialValues = {} }) => {
    const values = {};

    formFields.forEach(({ id, type, label, defaultValue, choices }) => {
      const fieldID = `input_${id}`;

      if (type === 'name') {
        values[`${fieldID}_3`] = initialValues.firstName ? initialValues.firstName : defaultValue;
        values[`${fieldID}_6`] = initialValues.lastName ? initialValues.lastName : defaultValue;
      } else if (type === 'radio' || type === 'select') {
        const parsedChoices = JSON.parse(choices);
        let selectedChoice = parsedChoices.find(({ isSelected }) => isSelected === true);
        if (!selectedChoice) [selectedChoice] = parsedChoices;

        values[fieldID] = selectedChoice.value;
      } else if (type === 'multiselect') {
        const parsedChoices = JSON.parse(choices);
        const choicesWithCorrectValues = getTransformedSliderChoices(parsedChoices);

        let selectedChoice = choicesWithCorrectValues.find(({ isSelected }) => isSelected === true);
        if (!selectedChoice) [selectedChoice] = choicesWithCorrectValues;

        values[fieldID] = selectedChoice.value;
      } else if (type === 'checkbox') {
        const parsedChoices = JSON.parse(choices);
        values[fieldID] = parsedChoices.filter(({ isSelected }) => isSelected === true);
      } else if (type === 'email') {
        values[fieldID] = initialValues.email ? initialValues.email : defaultValue;
      } else if (label.toLowerCase() === 'company name') {
        values[fieldID] = initialValues.companyName ? initialValues.companyName : defaultValue;
      } else {
        values[fieldID] = defaultValue;
      }
    });

    return values;
  },
  validationSchema: ({ data: { formFields } }) => {
    const schemaObject = {};

    formFields.forEach((field) => {
      const { id, type, isRequired, cssClass } = field;
      const fieldID = `input_${id}`;

      if (type === 'email') {
        schemaObject[fieldID] = Yup.string()
          .email('Please, enter valid email')
          .test('', 'Please, enter valid email', (value) => {
            if (!value) return false;
            if (!cssClass || !cssClass.includes('business-email')) return true;

            const [, emailDomain] = value.split('@');
            return !FREE_EMAIL_PROVIDER_DOMAINS.includes(emailDomain);
          });
      } else if (type === 'captcha') {
        schemaObject[fieldID] = Yup.string().required('This field is required');
      } else if (type === 'checkbox') {
        schemaObject[fieldID] = Yup.array().of(Yup.string());
      } else if (type === 'website') {
        schemaObject[fieldID] = Yup.string().url('Please, enter valid website URL');
      } else if (type === 'phone') {
        const phoneNumberRegexp = /^[+ ]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\sc./0-9]*$/g;
        schemaObject[fieldID] = Yup.string().matches(
          phoneNumberRegexp,
          'Please, enter valid phone number'
        );
      }

      if (isRequired === true) {
        if (type === 'name') {
          schemaObject[`${fieldID}_3`] = Yup.string().required('This field is required');
          schemaObject[`${fieldID}_6`] = Yup.string().required('This field is required');
        } else if (
          type === 'email' ||
          type === 'checkbox' ||
          type === 'website' ||
          type === 'phone'
        ) {
          schemaObject[fieldID] = schemaObject[fieldID].required('This field is required');
        } else {
          schemaObject[fieldID] = Yup.string().required('This field is required');
        }
      }
    });

    return Yup.object().shape(schemaObject);
  },
  handleSubmit: (
    values,
    {
      props: {
        data: { formId, apiURL, formFields },
        onSuccessfulFormSubmit,
      },
      resetForm,
      setSubmitting,
    }
  ) => {
    const method = 'POST';
    const route = `'forms/${formId}/submissions`;

    const key = process.env.GATSBY_GRAVITY_FORMS_KEY;
    const secret = process.env.GATSBY_GRAVITY_FORMS_SECRET;

    const currentDate = new Date();
    const expiration = 3600; // 1 hour
    const unixTime = parseInt(currentDate.getTime() / 1000, 10);
    const futureUnixTime = unixTime + expiration;

    const stringToSign = `${key}:${method}:${route}:${futureUnixTime}`;
    const signature = encodeURIComponent(
      CryptoJS.HmacSHA1(stringToSign, secret).toString(CryptoJS.enc.Base64)
    );

    const readyValues = {};
    formFields.forEach(({ id, type, choices, conditionalLogic }) => {
      const fieldID = `input_${id}`;

      const parsedConditionalLogic = JSON.parse(conditionalLogic);
      if (parsedConditionalLogic) {
        const {
          actionType,
          rules: [rule],
        } = parsedConditionalLogic;
        const ruleFieldName = `input_${rule.fieldId}`;

        if (actionType === 'show') {
          if (values[ruleFieldName] && values[ruleFieldName] !== rule.value) return;
        } else if (actionType === 'hide') {
          if (values[ruleFieldName] && values[ruleFieldName] === rule.value) return;
        }
      }

      if (type === 'name') {
        readyValues[`${fieldID}_3`] = values[`${fieldID}_3`];
        readyValues[`${fieldID}_6`] = values[`${fieldID}_6`];
      } else if (type === 'multiselect') {
        const parsedChoices = JSON.parse(choices);
        const choicesWithCorrectValues = getTransformedSliderChoices(parsedChoices);
        readyValues[fieldID] = choicesWithCorrectValues.find(
          ({ value }) => Number(values[fieldID]) === Number(value)
        ).text;
      } else if (type === 'checkbox') {
        const parsedChoices = JSON.parse(choices);
        parsedChoices.forEach(({ value }, index) => {
          if (values[fieldID].find((checkboxValue) => checkboxValue === value)) {
            readyValues[`${fieldID}_${index + 1}`] = value;
          }
        });
      } else {
        readyValues[fieldID] = values[fieldID];
      }
    });

    fetch(`${apiURL}/submissions?api_key=${key}&signature=${signature}&expires=${futureUnixTime}`, {
      method: 'POST',
      body: JSON.stringify(readyValues),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(({ is_valid }) => {
        if (is_valid === true) {
          if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'wegift-form-submit',
              formId,
            });
          }
          if (onSuccessfulFormSubmit) onSuccessfulFormSubmit();
          resetForm();
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
})(FormGeneratorView);
