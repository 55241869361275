import { graphql } from 'gatsby';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BookDemo } from 'components/blocks/book-demo';
import { MobileNavigation } from 'components/blocks/mobile-navigation';
import { AnnouncementBar } from 'components/shared/announcement-bar';
import { Modal } from 'components/shared/modal';

import { GlobalModal } from '../blocks/global-modal';

import { GlobalContext } from './global-context';
import { GlobalStyles } from './global-styles';
import { SEO } from './seo';

const Layout = ({ pageMetadata, children }) => {
  const [openModalName, setOpenModalName] = React.useState(null);
  const [isMobileNavigationOpen, setMobileNavigationOpen] = React.useState(false);
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1199.98px)',
  });

  React.useEffect(() => {
    if (openModalName || isMobileNavigationOpen) {
      document.querySelector('html').style.cssText = 'overflow: hidden';
      document.querySelector('body').style.cssText = 'overflow: hidden';
    } else {
      document.querySelector('html').style.cssText = '';
      document.querySelector('body').style.cssText = '';
    }
  }, [openModalName, isMobileNavigationOpen]);

  const openModal = (modalName) => setOpenModalName(modalName);
  const closeModal = () => setOpenModalName(null);

  const handleOpenMobileNavigation = () => setMobileNavigationOpen((value) => !value);

  const contextValue = {
    openModal,
    closeModal,
    isMobileNavigationOpen,
    handleOpenMobileNavigation,
  };

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
      speed: 400,
      offset: 50,
      updateURL: false,
    });
  }

  return (
    <>
      <GlobalStyles />
      <SEO {...pageMetadata} />

      <GlobalContext.Provider value={contextValue}>
        <AnnouncementBar />
        <div className="content-wrapper">{children}</div>
        {isMobileOrTablet && <MobileNavigation isOpen={isMobileNavigationOpen} />}
        {/* TODO: move the modal out of layout (as SuccessModal) */}
        <Modal isOpen={openModalName === 'book-demo'} onClose={closeModal}>
          <BookDemo />
        </Modal>
        <GlobalModal />
      </GlobalContext.Provider>
    </>
  );
};

export default Layout;

export const query = graphql`
  fragment wordpressPostPageMetadata on WpPost {
    seo {
      title
      metaDesc
      metaKeywords
      metaRobotsNoindex
      opengraphDescription
      opengraphTitle
      opengraphUrl
      opengraphImage {
        localFile {
          childImageSharp {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }

  fragment wordpressPagePageMetadata on WpPage {
    seo {
      title
      metaDesc
      metaKeywords
      metaRobotsNoindex
      opengraphDescription
      opengraphTitle
      opengraphUrl
      opengraphImage {
        localFile {
          childImageSharp {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`;
