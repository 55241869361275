import RCSlider from 'rc-slider';
import * as React from 'react';

import { Wrapper } from './slider.styles';

import 'rc-slider/assets/index.css';

export const Slider = ({ value, marks, onChange }) => (
  <Wrapper>
    <RCSlider value={value} marks={marks} included={false} step={null} onChange={onChange} />
  </Wrapper>
);
