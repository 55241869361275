import React from 'react';

import { Wrapper, Text, StyledLink, CloseButton, StyledCloseIcon } from './announcement-bar.styles';

export const AnnouncementBarView = ({ text, linkUrl, linkText, handleButtonClose, visible }) => (
  <Wrapper visible={visible} id="announcementBar">
    <Text>
      {text} {linkUrl && linkText && <StyledLink to={linkUrl}>{linkText}</StyledLink>}
    </Text>
    <CloseButton onClick={handleButtonClose}>
      <StyledCloseIcon />
    </CloseButton>
  </Wrapper>
);
